var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-default"},[(_vm.$route.query['hideNavbar'] === undefined)?_c('header',{staticClass:"layout-default__header",class:{ 'layout-default__header--compact': _vm.isHeaderCompact }},[_c('div',{staticClass:"layout-default__header__container"},[_c('button',{staticClass:"layout-default__header__mobile-menu",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();return _vm.mobileMenuToggle.apply(null, arguments)}}},[_c('IconMenuSvg')],1),_c('nav',{staticClass:"layout-default__header__nav",class:{ 'layout-default__header__nav--on': _vm.mobileMenu },on:{"click":function($event){$event.stopPropagation();}}},[_c('ul',{staticClass:"layout-default__header__nav__menu"},[_vm._l((_vm.$t('header.menu')),function(item,id){return _c('li',{key:id},[_c('router-link',{attrs:{"to":{
                name: 'home',
                hash: `#${id}`
              }},nativeOn:{"click":function($event){return _vm.navTo(id)}}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),_c('li',[_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('parents.header'),expression:"'parents.header'"}],attrs:{"to":{ name: 'parents' }}})],1),_vm._m(0)],2),_c('div',{staticClass:"layout-default__header__nav__lang"},[_c('LangSwitcher')],1)]),_c('div',{staticClass:"layout-default__header__tray"},[_c('router-link',{staticClass:"button button--compact button--outline button--color-2",attrs:{"to":{ name: 'download' }}},[_vm._v(" "+_vm._s(_vm.$t('header.linkDownload'))+" ")])],1)])]):_vm._e(),_c('main',{staticClass:"layout-default__main"},[_c('transition',{attrs:{"name":"transition-1","mode":"out-in"}},[_c('RouterView')],1)],1),(_vm.$route.query['hideNavbar'] === undefined)?_c('footer',{staticClass:"layout-default__footer"},[_c('div',{staticClass:"layout-default__footer__utils"},[_c('a',{staticClass:"layout-default__footer__help button button--compact button--outline button--color-2",attrs:{"href":"https://pkxd.zendesk.com","rel":"noopener","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.helpCenter'))+" ")]),_c('router-link',{staticClass:"layout-default__footer__logo-pkxd",attrs:{"to":{ name: 'home' }}},[_c('LogoPKXD',{attrs:{"light":""}})],1),_c('ul',{staticClass:"layout-default__footer__social"},[_c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCEG7wisom7uHObZfMeRIdIw","target":"_blank","title":"YouTube"}},[_c('ButtonYoutubeSvg')],1)]),_c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/pkxd.universe/","target":"_blank","title":"Instagram"}},[_c('ButtonInstagramSvg')],1)])])],1),_c('div',{staticClass:"layout-default__footer__support"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://policies.playpkxd.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.privacy'))+" ")])]),_vm._m(1)])]),_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://archives.playpkxd.com","rel":"noopener","target":"_blank"}},[_vm._v("Archives")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://creators.playpkxd.com/","target":"_blank"}},[_vm._v(" Creators ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-default__footer__onetrust"},[_c('a',{staticClass:"optanon-show-settings"},[_vm._v("Configurações dos cookies")])])
}]

export { render, staticRenderFns }