<template>
  <section class="create-section" id="create">
    <carousel
      class="create-section__carousel"
      :perPage="1"
      :navigationEnabled="true"
      :paginationEnabled="false"
    >
      <slide class="create-section__carousel__slide create-section__carousel__slide--avatar">
        <div class="create-section__carousel__slide__container">
          <div class="create-section__carousel__slide__object animation-floating">
            <img
              src="@/assets/images/create/avatar/character.png"
              alt="Character"
            >
          </div>

          <div class="create-section__carousel__slide__text">
            <div class="create-section__carousel__slide__text__container">
              <h3 v-html="$t('create.avatar.heading')"></h3>
              <p v-html="$t('create.avatar.text')"></p>
            </div>
          </div>
        </div>
      </slide>

      <slide class="create-section__carousel__slide create-section__carousel__slide--tree-house">
        <div class="create-section__carousel__slide__container">
          <div class="create-section__carousel__slide__object animation-floating">
            <img
              src="@/assets/images/create/tree-house/tree-house.png"
              alt="Tree house"
            >
          </div>

          <div class="create-section__carousel__slide__text">
            <div class="create-section__carousel__slide__text__container">
              <h3 v-html="$t('create.treeHouse.heading')"></h3>
              <p v-html="$t('create.treeHouse.text')"></p>
            </div>
          </div>
        </div>
      </slide>

      <slide class="create-section__carousel__slide create-section__carousel__slide--dance">
        <div class="create-section__carousel__slide__container">
          <div class="create-section__carousel__slide__object animation-floating">
            <img
              src="@/assets/images/create/dance/character.png"
              alt="Character"
            >
          </div>

          <div class="create-section__carousel__slide__text">
            <div class="create-section__carousel__slide__text__container">
              <h3 v-html="$t('create.dance.heading')"></h3>
              <p v-html="$t('create.dance.text')"></p>
            </div>
          </div>
        </div>
      </slide>

      <slide class="create-section__carousel__slide create-section__carousel__slide--pet">
        <div class="create-section__carousel__slide__container">
          <div class="create-section__carousel__slide__object animation-floating">
            <img
              src="@/assets/images/create/pet/character.png"
              alt="Character"
            >
          </div>

          <div class="create-section__carousel__slide__text">
            <div class="create-section__carousel__slide__text__container">
              <h3 v-html="$t('create.pet.heading')"></h3>
              <p v-html="$t('create.pet.text')"></p>
            </div>
          </div>
        </div>
      </slide>

      <slide class="create-section__carousel__slide create-section__carousel__slide--produce">
        <div class="create-section__carousel__slide__container">
          <div class="create-section__carousel__slide__object animation-floating">
            <img
              src="@/assets/images/create/produce/character.png"
              alt="Character"
            >
          </div>

          <div class="create-section__carousel__slide__text">
            <div class="create-section__carousel__slide__text__container">
              <h3 v-html="$t('create.produce.heading')"></h3>
              <p v-html="$t('create.produce.text')"></p>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </section>
</template>

<script>
export default {
  name: 'CreateSection'
}
</script>

<style lang="scss" scoped>
.create-section {
  background: #000;

  &__carousel {
    &__slide {
      $slide: &;

      display: flex;
      height: 700px;
      justify-content: center;

      &__container {
        flex: 1;
        display: flex;
        max-width: map-get($breakpoint, 'extra-large');
      }

      &__object {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }

      &__text {
        flex: 1;
        display: flex;
        align-items: center;

        &__container {
          padding: 0 100px;
        }

        h3 {
          font: {
            weight: 900;
            size: 3.5rem;
          }
          letter-spacing: -0.03em;
          word-spacing: 0.1em;
        }

        p {
          margin-top: 1em;
          font-size: 1.2rem;
          line-height: 130%;
        }
      }

      &--avatar {
        background: {
          color: nth($color-custom, 4);
          image: url('~@/assets/images/create/avatar/bg.jpg');
          position: 50%;
          repeat: no-repeat;
          size: cover;
        };

        #{$slide}__object {
          img {
            width: 460px;
            transform:
              translateY(-30px)
              scale(1.2);
          }
        }

        #{$slide}__text {
          h3 {
            color: nth($color-custom, 2);
          }

          p {
            color: #fff;
          }
        }
      }

      &--tree-house {
        background: {
          color: nth($color-custom, 6);
          image: url('~@/assets/images/create/tree-house/bg.jpg');
          position: 50% 100%;
          repeat: no-repeat;
          size: 100% auto;
        };

        #{$slide}__object {
          img {
            width: 460px;
            transform-origin: 0 50%;
            transform:
              translateY(40px)
              scale(2.1);
          }
        }

        #{$slide}__text {
          padding-bottom: 210px;

          h3 {
            color: nth($color-custom, 7);
          }

          p {
            color: nth($color-custom, 7);
          }
        }
      }

      &--dance {
        background: {
          color: nth($color-custom, 6);
          image:
            url('~@/assets/images/bg-smile-pattern.svg'),
            linear-gradient(90deg, nth($color-custom, 4), nth($color-custom, 7));
        };

        #{$slide}__object {
          justify-content: flex-start;

          img {
            transform: scale(0.9);
          }
        }

        #{$slide}__text {
          order: -1;

          h3 {
            color: nth($color-custom, 2);
          }

          p {
            color: #fff;
          }
        }
      }

      &--pet {
        background: nth($color-custom, 6);

        #{$slide}__container {
          background: {
            image: url('~@/assets/images/create/pet/boom.svg');
            position: 100% 50%;
            repeat: no-repeat;
            size: 60%;
          }
        }

        #{$slide}__object {
          justify-content: flex-start;

          img {
            transform-origin: 100% 50%;
            transform:
              translateY(-50px)
              scale(1.1);
          }
        }

        #{$slide}__text {
          order: -1;

          h3 {
            color: nth($color-custom, 4);
          }

          p {
            color: nth($color-custom, 4);
          }
        }
      }

      &--produce {
        background: nth($color-custom, 2);

        #{$slide}__object {
          justify-content: flex-start;

          img {
            transform-origin: 100% 50%;
            transform:
              translate(-150px, 50px)
              scale(0.9);
          }
        }

        #{$slide}__text {
          order: -1;

          h3 {
            color: nth($color-custom, 4);
          }

          p {
            color: nth($color-custom, 4);
          }
        }
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    &__carousel {
      height: 590px;

      &__slide {
        $slide: &;

        &__container {
          flex-direction: column;
        }

        &__object {
          flex: 0;
        }

        &__text {
          flex: 0;

          &__container {
            padding: 0 40px;
            text-align: center;
          }

          h3 {
            font-size: 2.5rem;
          }

          p {
            font-size: 1rem;
            line-height: 120%;
          }
        }

        &--avatar {
          #{$slide}__object {
            img {
              width: auto;
              height: 360px;
              transform:
                translateY(0)
                scale(0.9);
            }
          }

          #{$slide}__text {}
        }

        &--tree-house {
          background: {
            position: 50% calc(100% - 100px);
            size: auto 180%;
          };

          #{$slide}__object {
            img {
              transform-origin: 50% 50%;
              transform:
                translateY(70px)
                scale(0.9);
            }
          }

          #{$slide}__text {
            padding-bottom: 0;
          }
        }

        &--dance {
          #{$slide}__object {
            justify-content: center;

            img {
              height: 360px;
              transform:
                translateY(0)
                scale(0.8);
            }
          }

          #{$slide}__text {
            order: inherit;
          }
        }

        &--pet {
          #{$slide}__container {
            background: {
              position: 50% 40px;
              size: 130%;
            }
          }

          #{$slide}__object {
            justify-content: center;

            img {
              height: 360px;
              transform-origin: 50% 50%;
              transform:
                translateY(0)
                scale(0.8);
            }
          }

          #{$slide}__text {
            order: inherit;
          }
        }

        &--produce {
          background: nth($color-custom, 2);

          #{$slide}__object {
            justify-content: center;

            img {
              height: 360px;
              transform-origin: 50% 50%;
              transform:
                translate(0)
                scale(0.8);
            }
          }

          #{$slide}__text {
            order: inherit;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.create-section {
  .VueCarousel {
    &-wrapper {
      overflow: visible;
    }

    &-navigation {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: map-get($breakpoint, 'extra-large');
      transform: translateX(-50%);

      &-button {
        $size: 60px;

        @extend %user-select-off;

        width: $size;
        height: $size;
        overflow: hidden;
        outline: 0 !important;
        transform: translateY(-50%) !important;
        background: {
          position: 50% 50%;
          repeat: no-repeat;
          size: auto $size;
        };
        text-indent: -1000px;
      }

      &-prev {
        background-image: url('~@/assets/images/buttons/button-previous.svg');
      }

      &-next {
        background-image: url('~@/assets/images/buttons/button-next.svg');
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    .VueCarousel {
      &-wrapper {
        overflow: hidden;
      }

      &-navigation {
        width: calc(100% - 20px);

        &-button {
          $size: 50px;

          width: $size;
          height: $size;
          background-size: auto $size;
        }
      }
    }
  }
}
</style>
