<template>
  <section class="topics-section">
    <div class="topics-section__container">
      <div
        class="topics-section__card"
        v-for="(item, index) in $t('topics')"
        :key="index"
      >
        <Component
          class="topics-section__card__icon"
          :is="item.icon"
        />

        <div class="topics-section__card__text">
          <h4 v-html="item.heading"></h4>
          <p v-html="item.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TopicsSection'
}
</script>

<style lang="scss" scoped>
.topics-section {
  background: nth($color-custom, 1);

  &__container {
    display: flex;
    max-width: map-get($breakpoint, 'extra-large');
    margin: 0 auto;
  }

  &__card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    &:nth-child(even) {
      background: nth($color-custom, 5);
    }

    &__icon {
      height: 130px;
    }

    &__text {
      color: #fff;
      text-align: center;

      h4 {
        margin-top: 20px;
        font: {
          weight: 900;
          size: 1.5rem;
        }
      }

      p {
        margin-top: 10px;
        font-size: 1.1rem;
        line-height: 140%;
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    &__container {
      flex-direction: column;
    }

    &__card {
      flex-direction: row;
      padding: 30px 30px 30px 10px;

      &__icon {
        transform: scale(0.7);
      }

      &__text {
        flex: 1;
        padding-left: 10px;
        text-align: left;

        h4 {
          margin: 0;
          font-size: 1.2rem;
        }

        p {
          font-size: 0.9rem;
          line-height: 120%;
        }
      }
    }
  }
}
</style>
