<template>
  <section class="minigames-section">
    <div class="minigames-section__device">
      <div class="minigames-section__device__video">
        <video poster="@/assets/images/minigames/screenshot.jpg" width="100%" muted autoplay loop>
          <source src="@/assets/videos/minigames.mp4" type="video/mp4">
        </video>
      </div>
    </div>

    <div class="minigames-section__text">
      <div class="minigames-section__text__container">
        <h3 v-html="$t('minigames.heading')" />

        <p
          v-for="(item, index) in $t('minigames.text')"
          :key="index"
          v-html="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MinigamesSection'
}
</script>

<style lang="scss" scoped>
.minigames-section {
  display: flex;
  height: 600px;
  align-items: stretch;
  background: {
    color: map-get($color-util, 'light');
    image: url('~@/assets/images/bg-smile-pattern.svg');
  }

  &__device {
    flex: 1;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 60px;
      display: block;
      width: 805px;
      height: 400px;
      transform: translateY(-50%);
      background: {
        image: url('~@/assets/images/minigames/mock-phone.svg');
        repeat: no-repeat;
        size: cover;
      }
    }

    &__video {
      position: absolute;
      top: 50%;
      right: 74px;
      width: 773px;
      height: 357px;
      overflow: hidden;
      transform: translateY(-50%);

      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    flex: 1;
    display: flex;
    align-items: center;

    &__container {
      max-width: 390px;
      color: nth($color-custom, 4)
    }

    h3 {
      margin-bottom: 20px;
      font: {
        weight: 900;
        size: 3.5rem;
      }
      letter-spacing: -0.03em;
      word-spacing: 0.1em;
    }

    p {
      margin: 0.5em 0;
      font-size: 1.2rem;
      line-height: 130%;
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    height: 540px;
    flex-direction: column;

    &__device {
      &::after {
        top: inherit;
        bottom: 0;
        right: 40px;
        width: 463px;
        height: 230px;
        transform: translateY(0);

      }

      &__video {
        top: inherit;
        bottom: 12px;
        right: 48px;
        width: 444px;
        height: 205px;
        transform: translateY(0);
      }
    }

    &__text {
      text-align: center;
      justify-content: center;

      &__container {
        padding: 0 10px;
      }

      h3 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 120%;
      }
    }
  }
}
</style>
