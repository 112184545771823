<template>
  <div class="subscription-feature" :class="`subscription-feature--${type}`">
    <img :src="require(`@/assets/images/subscription/${type}.png`)" />
    <p v-html="text" />
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.subscription-feature {
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 2rem;

  > img {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
    object-fit: contain;
  }

  > p {
    color: white;
    font-family: 'Play';
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.4;
    text-transform: uppercase;
  }

  @include media(map-get($breakpoint, 'small'), $property: min-width) {
    flex-direction: row;

    margin-top: 1.2rem;

    > img {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  @include media(map-get($breakpoint, 'medium'), $property: min-width) {
    margin-top: 1.4rem;

    > img {
      width: 84px;
      height: 82px;
      margin-right: 38px;
    }

    > p {
      font-size: 1.1rem;
    }
  }

  @include media(map-get($breakpoint, 'large'), $property: min-width) {
    margin-top: 1.6rem;

    > img {
      width: 92px;
      height: 92px;
      margin-right: 32px;
    }

    > p {
      font-size: 1.2rem;
    }
  }

  @include media(map-get($breakpoint, 'extra-large'), $property: min-width) {
    margin-top: 1.8rem;

    > p {
      font-size: 1.4rem;
    }
  }
}
</style>

<style lang="scss">
.subscription-feature {
  &--gems > p > b {
    color: #AEFA19;
  }

  &--coins > p > b {
    color: #FFDD00;
  }
}
</style>
