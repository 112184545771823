import IndexView from '@/views/IndexView.vue'
import HomePage from '@/components/pages/HomePage.vue'
import DownloadPage from '@/components/pages/DownloadPage.vue'
import ParentsPage from '@/components/pages/ParentsPage.vue'

const langNavigator = navigator.language.replace(/-.+$/, '')

const routes = [
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  },
  {
    path: '/:lang',
    component: IndexView,
    meta: {
      langDefault: langNavigator || 'en'
    },
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage
      },
      {
        name: 'download',
        path: 'download',
        component: DownloadPage,
        props: route => ({ ...route.params, ...route.query })
      },
      {
        name: 'parents',
        path: 'parents',
        component: ParentsPage
      }
    ]
  }
]

export default routes
