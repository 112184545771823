import { render, staticRenderFns } from "./FaqSection.vue?vue&type=template&id=31d82096&scoped=true"
import script from "./FaqSection.vue?vue&type=script&lang=js"
export * from "./FaqSection.vue?vue&type=script&lang=js"
import style0 from "./FaqSection.vue?vue&type=style&index=0&id=31d82096&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d82096",
  null
  
)

export default component.exports