import i18n from '@/i18n'

const guard = (to, from, next) => {
  // Set locale
  const lang = to.params.lang
  const changesLang = lang !== i18n.locale

  if (changesLang) {
    const locales = Object.keys(i18n.messages)
    const langDefault = localStorage.getItem('lang') || to.meta.langDefault

    if (!locales.includes(lang)) {
      return next(langDefault)
    }

    i18n.locale = lang

    localStorage.setItem('lang', lang)
  }

  // Scroll top
  if (to.name !== 'home') {
    window.scrollTo(0, 0)
  }

  next()
}

export default guard
