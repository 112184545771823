<template>
  <div class="layout-default">
    <header
      class="layout-default__header"
      :class="{ 'layout-default__header--compact': isHeaderCompact }"
      v-if="$route.query['hideNavbar'] === undefined"
    >
      <div class="layout-default__header__container">
        <button
          type="button"
          class="layout-default__header__mobile-menu"
          @click.stop="mobileMenuToggle"
          tabindex="-1"
        >
          <IconMenuSvg />
        </button>

        <nav
          class="layout-default__header__nav"
          :class="{ 'layout-default__header__nav--on': mobileMenu }"
          @click.stop
        >
          <ul class="layout-default__header__nav__menu">
            <li
              v-for="(item, id) in $t('header.menu')"
              :key="id"
            >
              <router-link
                :to="{
                  name: 'home',
                  hash: `#${id}`
                }"
                @click.native="navTo(id)"
              >
                {{ item }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'parents' }" v-t="'parents.header'" />
            </li>
            <li>
              <a href="https://archives.playpkxd.com" rel="noopener" target="_blank">Archives</a>
            </li>
          </ul>

          <div class="layout-default__header__nav__lang">
            <LangSwitcher />
          </div>
        </nav>

        <div class="layout-default__header__tray">
          <router-link
            class="button button--compact button--outline button--color-2"
            :to="{ name: 'download' }"
          >
            {{ $t('header.linkDownload') }}
          </router-link>
        </div>
      </div>
    </header>

    <main class="layout-default__main">
      <transition
        name="transition-1"
        mode="out-in"
      >
        <RouterView />
      </transition>
    </main>

    <footer class="layout-default__footer" v-if="$route.query['hideNavbar'] === undefined">
      <div class="layout-default__footer__utils">
        <a
          class="layout-default__footer__help button button--compact button--outline button--color-2"
          href="https://pkxd.zendesk.com"
          rel="noopener"
          target="_blank"
        >
          {{ $t('footer.helpCenter') }}
        </a>

        <router-link
          class="layout-default__footer__logo-pkxd"
          :to="{ name: 'home' }"
        >
          <LogoPKXD light />
        </router-link>

        <ul class="layout-default__footer__social">
          <li>
            <a
              href="https://www.youtube.com/channel/UCEG7wisom7uHObZfMeRIdIw"
              target="_blank"
              title="YouTube"
            >
              <ButtonYoutubeSvg />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/pkxd.universe/"
              target="_blank"
              title="Instagram"
            >
              <ButtonInstagramSvg />
            </a>
          </li>
        </ul>
      </div>

      <div class="layout-default__footer__support">
        <ul>
          <li>
            <a href="https://policies.playpkxd.com" target="_blank">
              {{ $t('footer.privacy') }}
            </a>
          </li>
          <li>
            <a href="https://creators.playpkxd.com/" target="_blank">
              Creators
            </a>
          </li>
        </ul>
      </div>

      <div class="layout-default__footer__onetrust">
        <a class="optanon-show-settings">Configurações dos cookies</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'IndexView',

  data () {
    return {
      mobileMenu: false,
      isHeaderCompact: false
    }
  },

  methods: {
    headerCompact () {
      this.isHeaderCompact = (window.innerHeight / 2) < window.scrollY
    },

    navTo (target) {
      const scrollTopDiff = 65

      this.mobileMenuToggle()
      this.$navScroll(`#${target}`, scrollTopDiff)
    },

    mobileMenuToggle () {
      this.mobileMenu = !this.mobileMenu

      if (this.mobileMenu) {
        return document.addEventListener('click', this.mobileMenuToggle)
      }

      document.removeEventListener('click', this.mobileMenuToggle)
    }
  },

  mounted () {
    window.addEventListener('scroll', this.headerCompact)
  }
}
</script>
