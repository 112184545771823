<template>
  <div>
    <HeroSection />
    <BakeryShopSection />
    <SubscriptionSection />
    <GiftsSection />
    <MultiplayerSection />
    <DownloadSection />
    <CreateSection />
    <MinigamesSection />
    <TopicsSection />
    <FaqSection />
  </div>
</template>

<script>
export default {
  name: 'HomePage',

  mounted () {
    this.$nextTick(() => this.$navScroll(this.$route.hash))
  }
}
</script>
