<template>
  <section class="gifts" id="gifts">
    <div class="gifts__content">
      <h1 v-html="$t('gifts.title')" />
      <h6 v-t="'gifts.description'" />

      <div>
        <img src="@/assets/images/gifts-background.svg" />
        <img src="@/assets/images/gifts.png" />
      </div>

      <a class="button" href="https://gifts.playpkxd.com" target="_blank" rel="noopener" v-t="'gifts.cta'" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'GiftsSection'
}
</script>

<style lang="scss" scoped>
.gifts {
  background-image: url('~@/assets/images/eggs-background.svg');
  background-repeat: repeat;
  background-size: 500px auto;
  background-position: bottom -70px left -20px;
  background-color: nth($color-custom, 1);

  display: flex;
  align-items: center;
  text-align: center;
  padding: 80px 24px 80px 24px;

  &__content {
    margin: 0 auto;
    max-width: 300px;
    color: white;

    > h1 {
      font-weight: 700;
      margin-bottom: 12px;
      font-size: 22px;
      line-height: 1.4;
      text-shadow: 0px 4px 70px #7B27F1, 0px 4px 26px #7B27F1, 0px 4px 0px #000000;
    }

    > h6 {
      line-height: 1.4;
      text-transform: none;
      font-weight: 400;
      margin: 0 12px;
    }

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      height: 200px;
      margin: 0 auto;

      > img {
        position: absolute;
        display: block;
        max-width: 100%;

        &:last-child {
          max-width: 75%;
        }
      }
    }

    > .button {
      position: relative;
      margin: 24px auto 0 auto;
      max-width: 300px;
      font-size: 0.8rem;
      line-height: 1.4;
    }
  }

  @include media(map-get($breakpoint, 'small'), min-width) {
    padding: 60px 24px 60px 24px;

    &__content {
      max-width: 400px;

      > div {
        height: 350px;
      }

      > .button {
        max-width: 300px;
        font-size: 1.2rem;
      }
    }
  }

  @include media(map-get($breakpoint, 'medium'), min-width) {
    padding: 80px 32px 80px 32px;

    &__content {
      max-width: 550px;

      > h1 {
        margin-bottom: 24px;
        font-size: 26px;
      }

      > h6 {
        margin: 0 56px;
        line-height: 1.3;
        font-size: 18px;
      }

      > .button {
        margin: 40px auto 0 auto;
        max-width: 500px;
      }
    }
  }

  @include media(map-get($breakpoint, 'large'), min-width) {
    padding: 120px 48px 120px 48px;

    &__content {
      max-width: 750px;

      > h1 {
        margin-bottom: 24px;
        font-size: 32px;
      }

      > div {
        max-width: 600px;
      }
    }
  }

  @include media(map-get($breakpoint, 'extra-large'), min-width) {
    // min-height: 80vh;
  }
}
</style>
