<template>
  <div class="stacked-list" :class="{ 'stacked-list--cozy': cozy }">
    <input type="text" :placeholder="$t('faq.searchPlaceholder')" class="stacked-list__search" v-model="searchInput" v-if="search" />

    <ul class="stacked-list__items" v-if="searchResult.length">
      <li class="stacked-list__item" :class="{ 'stacked-list__item--expanded': item.isExpanded }" v-for="(item, index) in searchResult" :key="index">
        <a :href="`#issue-${index}-answer`" class="stacked-list__item__title" @click.prevent="itemToggle($event)">
          <span>{{ item.title }}</span>
        </a>

        <div class="stacked-list__item__content" :id="`issue-${index}-answer`">
          <p v-for="(item, index) in item.content" :key="index">
            <span v-if="typeof item !== 'object'" v-html="item" />

            <ul v-else>
              <li v-for="(item, index) in item" :key="index" v-html="item" />
            </ul>
          </p>
        </div>
      </li>
    </ul>

    <div class="stacked-list__empty" v-else >
      <div class="stacked-list__empty__emoji">
        (&gt;_&lt;)
      </div>

      <p>Oops! {{ $t('faq.emptyState') }} <strong>{{ searchInput }}</strong>.</p>

      <p>
        <a href="javascript:;" @click.prevent="searchInput = ''">
          {{ $t('faq.searchClear') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StackedList',

  props: {
    items: Array,
    search: Boolean,
    cozy: Boolean
  },

  data () {
    return {
      searchInput: ''
    }
  },

  computed: {
    searchResult () {
      const query = this.searchInput.toLowerCase().trim()
      const items = this.items

      if (!query) {
        return items
      }

      return items.filter(item => {
        return Object.values(item)
          .filter(item => typeof item !== 'boolean')
          .flat()
          .join(' ')
          .toLowerCase()
          .includes(query)
      })
    }
  },

  methods: {
    itemToggle (event) {
      const parent = event.target.closest('.stacked-list__item')
      const expandedClass = 'stacked-list__item--expanded'

      parent.classList.toggle(expandedClass)
    }
  }
}
</script>

<style lang="scss" scoped>
.stacked-list {
  $parent: &;

  &__search {
    display: block;
    width: 100%;
    padding: 20px 20px 20px 60px;
    margin-bottom: 15px;
    border: 1px solid map-get($color-util, 'lighter');
    border-bottom-width: 3px;
    border-radius: 2px;
    outline: 0;
    transition: all 0.1s;
    background: {
      color: #fff;
      image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMy4yOCA1MTMuMjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMy4yOCA1MTMuMjg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTQ5NS4wNCw0MDQuNDhMNDEwLjU2LDMyMGMxNS4zNi0zMC43MiwyNS42LTY2LjU2LDI1LjYtMTAyLjRDNDM2LjE2LDk3LjI4LDMzOC44OCwwLDIxOC41NiwwUzAuOTYsOTcuMjgsMC45NiwyMTcuNiAgICBzOTcuMjgsMjE3LjYsMjE3LjYsMjE3LjZjMzUuODQsMCw3MS42OC0xMC4yNCwxMDIuNC0yNS42bDg0LjQ4LDg0LjQ4YzI1LjYsMjUuNiw2NCwyNS42LDg5LjYsMCAgICBDNTE4LjA4LDQ2OC40OCw1MTguMDgsNDMwLjA4LDQ5NS4wNCw0MDQuNDh6IE0yMTguNTYsMzg0Yy05Mi4xNiwwLTE2Ni40LTc0LjI0LTE2Ni40LTE2Ni40UzEyNi40LDUxLjIsMjE4LjU2LDUxLjIgICAgczE2Ni40LDc0LjI0LDE2Ni40LDE2Ni40UzMxMC43MiwzODQsMjE4LjU2LDM4NHoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiNFOEU4RTgiLz4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+Cg==');
      position: 20px 50%;
      repeat: no-repeat;
      size: auto 20px;
    }
    font: {
      weight: 400;
      size: 1.1rem;
      family: $font-regular;
    }

    &:focus {
      border-color: nth($color-custom, 1);
    }

    &::placeholder {
      color: map-get($color-util, 'muted');
    }
  }

  &__item {
    text-align: left;

    #{$parent}--cozy & {
      &:nth-child(n + 2) {
        a {
          border-top: 1px solid map-get($color-util, 'lighter');
        }
      }

      &__title {
        padding: 30px 0;
      }
    }

    #{$parent}--cozy &--expanded & {
      &__content {
        padding-bottom: 20px;
      }
    }

    &--expanded & {
      &__title {
        &::before {
          transform: rotate(-45deg);
        }
      }

      &__content {
        display: block;
      }
    }

    &__title {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px 0;
      cursor: pointer;
      transition: color 0.1s;
      font: {
        weight: 700;
        size: 1.1rem;
      }
      line-height: 140%;

      &:hover {
        &::before {
          background-color: lighten(nth($color-custom, 1), 10%);
        }
      }

      &::before {
        $size: 20px;

        content: '';
        flex: $size 0 0;
        height: $size;
        margin-right: 10px;
        border: 3px solid nth($color-custom, 3);
        border-radius: 100%;
        transition: all 0.1s;
        background: {
          color: nth($color-custom, 1);
          image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIGNsYXNzPSIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTQyNi42NjcsMTcwLjY2N2gtODUuMzMzVjg1LjMzM0MzNDEuMzMzLDM4LjI3MiwzMDMuMDYxLDAsMjU2LDBzLTg1LjMzMywzOC4yNzItODUuMzMzLDg1LjMzM3Y4NS4zMzNIODUuMzMzICAgIEMzOC4yNzIsMTcwLjY2NywwLDIwOC45MzksMCwyNTZzMzguMjcyLDg1LjMzMyw4NS4zMzMsODUuMzMzaDg1LjMzM3Y4NS4zMzNDMTcwLjY2Nyw0NzMuNzI4LDIwOC45MzksNTEyLDI1Niw1MTIgICAgczg1LjMzMy0zOC4yNzIsODUuMzMzLTg1LjMzM3YtODUuMzMzaDg1LjMzM0M0NzMuNzI4LDM0MS4zMzMsNTEyLDMwMy4wNjEsNTEyLDI1NlM0NzMuNzI4LDE3MC42NjcsNDI2LjY2NywxNzAuNjY3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iIzc0RkJENyIvPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4K);
          position: 50%;
          repeat: no-repeat;
          size: 10px;
        }
      }
    }

    &__content {
      display: none;
      padding-left: 35px;

      p {
        margin-bottom: 1em;
        font-size: 1rem;
        line-height: 140%;
      }

      ul {
        li {
          list-style: disc;
          margin: 0 0 0.5em 20px;
        }
      }
    }
  }

  &__empty {
    margin-top: 30px;
    color: map-get($color-util, 'light');
    text-align: center;

    &__emoji {
      margin-bottom: 30px;
      color: map-get($color-util, 'muted');
      font: {
        weight: 900;
        size: 4rem;
      }
    }

    strong {
      color: map-get($color-util, 'darkest');
    }

    p {
      margin: 1em 0;
      font-size: 1rem;
      line-height: 140%;
    }

    a {
      @extend %link;
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    &__item {
      &__title {
        font-size: 1rem;
        line-height: 120%;
      }

      &__content {
        p {
          font-size: 0.9rem;
          line-height: 130%;
        }
      }
    }
  }
}
</style>
