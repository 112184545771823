<template>
  <section class="videos-section" id="videos">
    <div class="videos-section__container">
      <carousel
        class="videos-section__carousel"
        :perPageCustom="[[0, 1], [768, 2]]"
        :navigationEnabled="true"
        :paginationPadding="5"
      >
        <slide
          class="videos-section__carousel__slide"
          v-for="(item, index) in videos"
          :key="index"
        >
          <a
            :href="`https://www.youtube.com/watch?v=${item.id}`"
            target="_blank"
            class="videos-section__carousel__card"
          >
            <div class="videos-section__carousel__card__cover">
              <picture>
                <source :srcset="item.snippet.thumbnails.medium.url" media="(max-width: 768px)">
                <img :src="item.snippet.thumbnails.standard.url" alt="Thumbnail">
              </picture>

              <ButtonPlaySvg />
            </div>

            <div class="videos-section__carousel__card__description">
              <p>{{ item.snippet.title }}</p>
              <IconYoutubeSvg />
            </div>
          </a>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'VideosSection',

  data () {
    return {
      videos: null
    }
  },

  computed: {
    locale () {
      return this.$i18n.locale
    }
  },

  methods: {
    async getVideosList () {
      const ids = this.$t('videos.ids').join()
      const apiKey = 'AIzaSyB9Wjp8Wh9TUHxma8H8Y2ODq0a2Cn5kpF4'
      const apiUrl = `https://www.googleapis.com/youtube/v3/videos?part=id,snippet&id=${ids}&key=${apiKey}`
      const response = await axios.get(apiUrl).catch(error => error)

      this.videos = response?.data?.items
    }
  },

  watch: {
    locale () {
      this.getVideosList()
    }
  },

  mounted () {
    this.getVideosList()
  }
}
</script>

<style lang="scss" scoped>
.videos-section {
  background: map-get($color-util, 'lighter');

  &__container {
    max-width: map-get($breakpoint, 'extra-large');
    padding: 80px 60px;
    margin: 0 auto;
  }

  &__carousel {
    padding: 0 20px;

    &__slide {
      padding: 30px;
    }

    &__card {
      display: flex;
      height: 370px;
      flex-direction: column;
      border-radius: 2em;
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(#000, 0.2);
      transition: all 0.2s;

      &:hover {
        box-shadow: 0 0 15px 0 rgba(#000, 0.2);
        transform: scale(1.03);
      }

      &__cover {
        position: relative;
        height: 250px;

        picture {
          position: relative;
          display: block;
          height: 100%;
          overflow: hidden;
          border-radius: 2em 2em 0 0;
          background: #000;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 120px;
          opacity: 0.7;
          transform: translate(-50%, -50%);
        }
      }

      &__description {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;

        p {
          flex: 1;
          color: #000;
          font: {
            weight: 900;
            size: 1.2rem;
          }
          line-height: 130%;
        }

        svg {
          width: 50px;
          margin-left: 40px;
        }
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    &__container {
      padding: 40px 0;
    }

    &__carousel {
      padding: 0;

      &__slide {
        padding: 20px;
      }

      &__card {
        height: 290px;

        &__cover {
          height: 190px;

          svg {
            width: 90px;
          }
        }

        &__description {
          padding: 0 20px;

          p {
            font-size: 0.9rem;
            line-height: 120%;
          }

          svg {
            width: 40px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.videos-section {
  .VueCarousel {
    &-navigation {
      &-button {
        $size: 60px;

        @extend %user-select-off;

        width: $size;
        height: $size;
        overflow: hidden;
        margin-top: -25px;
        outline: 0 !important;
        background: {
          position: 50% 50%;
          repeat: no-repeat;
          size: auto $size;
        };
        text-indent: -1000px;
      }

      &-prev {
        background-image: url('~@/assets/images/buttons/button-previous.svg');
      }

      &-next {
        background-image: url('~@/assets/images/buttons/button-next.svg');
      }
    }

    &-dot {
      $size: 20px !important;

      @extend %user-select-off;

      width: $size;
      height: $size;
      padding: 10px !important;
      outline: 0 !important;
      background-color: map-get($color-util, 'light') !important;

      &--active {
        background-color: #000 !important;
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    .VueCarousel {
      &-navigation {
        display: none;
      }

      &-dot {
        $size: 15px !important;

        width: $size;
        height: $size;
      }
    }
  }
}
</style>
