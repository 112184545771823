<template>
  <section class="multiplayer-section" id="discover">
    <div class="multiplayer-section__text">
      <div class="multiplayer-section__text__container">
        <h3 v-html="$t('multiplayer.heading')"></h3>

        <p
          v-for="(item, index) in $t('multiplayer.text')"
          :key="index"
          v-html="item"
        >
        </p>
      </div>
    </div>

    <div class="multiplayer-section__device">
      <div class="multiplayer-section__device__video">
        <video poster="@/assets/images/multiplayer/screenshot.jpg" width="100%" muted autoplay loop>
          <source src="@/assets/videos/gameplay.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MultiplayerSection'
}
</script>

<style lang="scss" scoped>
.multiplayer-section {
  display: flex;
  height: 640px;
  align-items: stretch;
  background: {
    image: url('~@/assets/images/multiplayer/block-pixel.svg');
    position: 100% 100%;
    repeat: no-repeat;
  }

  &__text {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: {
      image: url('~@/assets/images/multiplayer/display.svg');
      position: 100% calc(100% - 160px),;
      repeat: no-repeat;
      size: auto 345px;
    }

    &__container {
      max-width: 560px;
      padding: 0 40px 270px;
      color: #fff;
    }

    h3 {
      margin-bottom: 20px;
      font: {
        weight: 900;
        size: 2rem;
      }
      letter-spacing: -0.03em;
      word-spacing: 0.1em;
    }

    p {
      margin: 0.5em 0;
      font-size: 1.2rem;
      line-height: 130%;
    }
  }

  &__device {
    flex: 1;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 157px;
      left: 0;
      display: block;
      width: 805px;
      height: 400px;
      background: {
        image: url('~@/assets/images/multiplayer/mock-phone.svg');
        repeat: no-repeat;
        size: cover;
      }
    }

    &__video {
      position: absolute;
      bottom: 180px;
      left: 15px;
      width: 773px;
      height: 357px;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    height: 565px;
    flex-direction: column;
    background: {
      position: 100% 0;
      size: auto 60px;
    }

    &__text {
      align-items: flex-start;
      justify-content: center;
      background: {
        image: url('~@/assets/images/multiplayer/display-mobile.svg');
        position: calc(50% + 10px) 0;
        size: 270px auto;
      }

      &__container {
        width: 270px;
        padding: 20px 5px 0 55px;
        text-align: center;

        h3 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
          line-height: 120%;
        }
      }
    }

    &__device {
      order: -1;

      &::after {
        top: 55px;
        bottom: inherit;
        left: 40px;
        width: 463px;
        height: 230px;
      }

      &__video {
        top: 67px;
        bottom: inherit;
        left: 48px;
        width: 444px;
        height: 205px;
      }
    }
  }
}
</style>
