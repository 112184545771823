<template>
  <section class="faq-section" id="faq">
    <div class="faq-section__container">
      <div class="faq-section__issues">
        <div class="faq-section__issues__header">
          <h3>{{ $t('faq.header.section.heading') }}</h3>
          <p>{{ $t('faq.header.section.text') }}</p>
        </div>

        <StackedList :items="issues" />

        <a
          class="button faq-section__issues__more"
          href="https://pkxd.zendesk.com"
          rel="noopener"
          target="_blank"
        >
          {{ $t('faq.more') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FaqSection',

  computed: {
    ...mapGetters('faq', { issues: 'issuesHome' })
  }
}
</script>

<style lang="scss" scoped>
.faq-section {
  &__container {
    max-width: map-get($breakpoint, 'extra-large');
    min-height: 540px;
    padding: 60px 20px;
    margin: 0 auto;
    background: {
      image: url('~@/assets/images/faq/character.png');
      position: 100% 100%;
      repeat: no-repeat;
      size: 40% auto;
    }
  }

  &__issues {
    width: 50%;

    &__header {
      margin-bottom: 15px;

      h3 {
        margin-bottom: 20px;
        font: {
          weight: 900;
          size: 3.5rem;
        }
        letter-spacing: -0.03em;
        word-spacing: 0.1em;
      }

      p {
        font-size: 1.1rem;
        line-height: 140%;
      }
    }

    &__more {
      margin-top: 30px;
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    &__container {
      min-height: auto;
      padding: 40px 20px;
      background: none;
    }

    &__issues {
      width: 100%;
      text-align: center;

      &__header {
        h3 {
          font-size: 2.5rem;
        }

        p {
          font-size: 1rem;
          line-height: 120%;
        }
      }
    }
  }
}
</style>
