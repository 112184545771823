<template>
  <div class="download-page">
    <section class="download-page__options" id="download">
      <transition name="transition-1" mode="out-in">
        <div class="download-page__options__container" v-if="!downloadStarted">
          <div class="download-page__options__header">
            <LogoPKXD class="download-page__options__header__logo" />
            <h1 class="download-page__options__header__heading" v-t="'download.options.heading'" />
          </div>

          <a href="#desktop-faq" class="download-page__options__faq">
            {{ $t('closedBeta.faq.cta') }}
          </a>

          <div class="download-page__options__body">
            <div class="download-page__options__choose">
              <div
                class="download-page__options__choose__type"
                v-for="item in options"
                :key="item.category"
              >
                <h5>{{ item.category }}</h5>

                <ul>
                  <li v-for="item in item.platforms" :key="item.id">
                    <a href="javascript:;" :id="`download_${item.id}`" @click="download(item)">
                      <Component :is="item.icon" />
                      <span>{{ item.id }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="transition-1" mode="out-in">
        <div
          class="download-page__options__container"
          v-if="downloadStarted"
        >
          <div class="download-page__options__header">
            <LogoPKXD class="download-page__options__header__logo" />
            <h3 class="download-page__options__header__subheading" v-t="'download.options.success.subheading'" />
            <h1 class="download-page__options__header__heading" v-t="'download.options.success.heading'" />
          </div>

          <div class="download-page__options__body">
            <p>
              <a href='#faq-desktop' @click="['mac', 'windows'].includes(lastDownloadedPlatformId) ? isDesktopFaqOpen = true : isDesktopFaqOpen = false">
                {{ $t('download.options.success.linkHelp') }}
              </a>
            </p>

            <p>
              <a
                class="button button--compact button--outline"
                href="https://playpkxd.com"
                target="_blank"
              >
                {{ $t('download.options.success.linkHome') }}
              </a>
            </p>
          </div>
        </div>
      </transition>

      <Modal class="download-page__options__consent-modal" v-show="isModalOpen" :close="closeModal">
        <div slot="header" class="download-page__options__consent-modal__header">
          <LogoPKXD class="logo" />
          <img src="@/assets/images/download/consent.jpg" />
        </div>
        <div slot="body">
          <h3 v-t="'closedBeta.terms.title'" />
          <div>
            <p v-for="(content, i) in $t('closedBeta.terms.content')" :key="i">
              <ul v-if="Array.isArray(content)">
                <li v-for="(entry, i) in content" :key="i">
                  {{entry}}
                </li>
              </ul>
              <span v-else>{{ content }}</span>
            </p>
          </div>
        </div>
        <div slot="footer" class="download-page__options__consent-modal__footer">
          <div class="checkbox">
            <input v-model="consented" type="checkbox" />
            {{ $t('closedBeta.terms.consent') }}
          </div>
          <a href="javascript:;" class="button button-flat button--compact" :class="{ 'button--disabled': !consented }"  @click="download(consentingPlatform)">
            {{ $t('closedBeta.terms.download') }}
          </a>
        </div>
      </Modal>
    </section>

    <DesktopFaqSection id="desktop-faq" />
  </div>
</template>

<script>
import axios from 'axios'
import yaml from 'js-yaml'

export default {
  name: 'DownloadPage',

  data () {
    return {
      isModalOpen: false,
      consented: false,
      consentingPlatform: null,

      lastDownloadedPlatformId: null,
      downloadStarted: false,
      optionsAll: [
        {
          featureToggle: true,
          category: this.$t('download.options.categoryDesktop'),
          platforms: [
            {
              id: 'windows',
              url: 'https://pkxd-desktop.pkds.it/production/launcher/windows/PK+XD+Launcher+Web+Setup+0.7.0.exe',
              icon: 'IconWindowsSvg',
              requireConsent: false
            },
            {
              id: 'mac',
              url: 'https://pkxd-desktop.pkds.it/production/launcher/mac/PK+XD+Launcher-0.7.0.dmg',
              icon: 'IconAppleSvg',
              requireConsent: false
            }
          ]
        },
        {
          featureToggle: true,
          category: this.$t('download.options.categoryMobile'),
          platforms: [
            {
              id: 'android',
              url: 'https://play.google.com/store/apps/details?id=com.movile.playkids.pkxd&hl=en',
              icon: 'IconAndroidSvg'
            },
            {
              id: 'ios',
              url: 'https://apps.apple.com/us/app/pk-xd-explore-the-universe/id1449842729',
              icon: 'IconAppleSvg'
            }
          ]
        }
      ]
    }
  },

  computed: {
    options () {
      return this.optionsAll.filter(item => item.featureToggle)
    }
  },

  methods: {
    download (platform) {
      if (platform.requireConsent && !this.consented) {
        this.isModalOpen = true
        this.consentingPlatform = platform
        return
      }

      this.lastDownloadedPlatformId = platform.id
      if (platform.requireConsent) {
        this.closeModal()
      }

      window.open(platform.url, '__blank')
      this.downloadStarted = true
      window.scrollTo(0, 0)
    },

    closeModal () {
      this.isModalOpen = false
      this.consentingPlatform = null
    },

    fetchLatestVersion (platform) {
      const api = {
        windows: 'https://pkxd-desktop.pkds.it/production/launcher/windows/latest.yml',
        mac: 'https://pkxd-desktop.pkds.it/production/launcher/mac/latest-mac.yml'
      }

      const url = api[platform]
      return axios
        .get(url)
        .then(response => yaml.load(response.data).version)
        .catch(error => { throw error })
    }
  },

  mounted () {
    this.options.forEach(item => {
      item.platforms.forEach(async item => {
        if (/LATEST-VERSION/.test(item.url)) {
          const version = await this.fetchLatestVersion(item.id)
          item.url = item.url.replace('LATEST-VERSION', version)
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.download-page {
  padding: 60px 20px;
  padding-bottom: 0;
  margin-top: $header-height;

  &__options {
    margin-bottom: 80px;

    &__container {
      max-width: map-get($breakpoint, 'extra-large');
      margin: 0 auto;
    }

    &__header {
      text-align: center;
      margin-bottom: 40px;

      &__logo {
        width: 120px;
        margin-bottom: 30px;
      }

      &__heading {
        font: {
          weight: 900;
          size: 3.5rem;
        }
        letter-spacing: -0.03em;
        word-spacing: 0.1em;
      }

      &__subheading {
        margin-bottom: 10px;
        font: {
          weight: 900;
          size: 1.7rem;
        }
        letter-spacing: -0.03em;
        word-spacing: 0.1em;
      }
    }

    &__faq {
      text-align: center;
      display: block;
      width: min-content;
      min-width: 400px;
      margin: 0 auto;
      font-size: 18px;
    }

    &__body {
      margin-top: 40px;
      text-align: center;

      p {
        margin: 2em 0;
        font-size: 1.3rem;
        line-height: 130%;
      }

      .button {
        margin: 0 10px;
      }
    }

    &__choose {
      display: flex;
      justify-content: center;
      text-align: center;

      &__type {
        padding: 0 40px;

        h5 {
          font: {
            weight: 900;
            size: 1.5rem;
          }
        }

        ul {
          margin-top: 20px;

          li {
            display: inline-block;
          }

          a {
            $size: 130px;
            $color-primary: nth($color-custom, 3);
            $color-secondary: nth($color-custom, 1);

            display: flex;
            flex-direction: column;
            width: $size;
            height: $size;
            justify-content: center;
            margin: 0 10px;
            border: 3px solid $color-primary;
            border: {
              bottom-width: 7px;
              radius: 0.8em;
            }
            background: $color-secondary;

            > svg {
              height: 50px;
            }

            > span {
              margin-top: 15px;
              color: $color-primary;
              font: {
                weight: 900;
                size: 0.9rem;
                family: $font-regular;
              }
              text-transform: uppercase;
            }

            &:hover {
              background-color: lighten($color-secondary, 5%);
            }
          }
        }
      }
    }

    &__consent-modal {
      font-family: Roboto, Lato, sans-serif;
      color: #494262;
      line-height: 1.5;

      &__header {
        position: relative;

        img.logo {
          position: absolute;
          left: 10%;
          top: 20%;
          height: 60%;
        }

        img:not(.logo) {
          display: block;
          width: 100%;
          border-radius: 8px;
          margin-bottom: 18px;
        }
      }

      h3 {
        margin-bottom: 18px;
        font: {
          size: 28px;
          weight: 700
        };
      }

      p {
        margin-bottom: 6px;
        font-size: 14px;
        text-indent: 20px;
      }

      ul {
        list-style: disc;
        padding-left: 20px;
        text-indent: 0;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #BDBDBD;
        border-radius: 8px;
        padding: 8px;

        > .checkbox {
          display: flex;
          align-items: center;

          > input {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }

        > .button {
          display: flex;
          align-items: center;
          color: white;
          border: none;
          background-color: nth($color-custom, 8);
          height: 40px;
          border-radius: 8px;
          padding-top: 0;
          padding-bottom: 0;
          font-family: Roboto, Lato, sans-serif;
          font-weight: 700;

          &--disabled {
            background-color: lightgray;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'medium')) {
    padding: 30px 20px;
    margin-top: $header-height-medium;

    &__options {
      &__header {
        &__heading {
          font-size: 2.5rem;
        }

        &__subheading {
          font-size: 1.3rem;
        }
      }

      &__body {
        margin-top: 40px;

        p {
          font-size: 1rem;
          line-height: 120%;
        }
      }

      &__choose {
        flex-direction: column;

        &__type {
          padding: 0;
          margin-bottom: 40px;

          ul {
            a {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
</style>
