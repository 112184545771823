import svgComponents from './svg'

const requireContext = require.context('.', true, /.+\.vue$/)

const vueComponents = requireContext.keys().map(item => {
  const component = requireContext(item).default
  const name = component.name || item.split('/').splice(-1)[0].replace(/\.vue$/, '')

  return [
    name,
    component
  ]
})

const install = Vue => {
  const components = [
    ...svgComponents,
    ...vueComponents
  ]

  components.forEach(item => {
    Vue.component(item[0], item[1])
  })
}

export default install
