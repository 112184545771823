import i18n from '@/i18n'

const getters = {
  issues () {
    const { issues } = i18n.messages[i18n.locale].faq

    return issues.filter(item => item.isActive === true)
  },

  issuesHome (state, getters) {
    return getters.issues.filter(item => item.isHome === true)
  },

  issuesDownload (state, getters) {
    return getters.issues.filter(item => item.isDownload === true)
  }
}

export default getters
