<template>
  <div class="parents-page">
    <div class="parents-page__content">
      <h1 v-t="'parents.title'" />
      <h2 v-t="'parents.subtitle'" />
      <h3 v-t="'parents.description'" />

      <div class="parents-page__content__faq">
        <div
          class="parents-page__content__faq__question"
          :class="{ 'parents-page__content__faq__question--open': expanded === i }"
          :key="i"
          v-for="(question, i) in $t('parents.faq')"
        >
          <div @click="expanded = (expanded == i ? null : i)">
            {{ question.title }}
            <IconArrowDown />
          </div>
          <HeightCollapsible :is-open="expanded === i">
            <p v-for="(text, i) in question.description" :key="i" v-html="text" />
          </HeightCollapsible>
        </div>
      </div>
    </div>

    <img src="@/assets/images/parents/character.png" />
  </div>
</template>

<script>
import HeightCollapsible from 'vue-height-collapsible'

export default {
  name: 'ParentsPage',

  components: { HeightCollapsible },

  data: () => ({
    expanded: null
  })
}
</script>

<style lang="scss" scoped>
.parents-page {
  @include grid-pattern(linear-gradient(180deg, #FF2D62 40%, #52c1e2 100%));

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 180px 60px 0 60px;
  min-height: 100vh;
  font-family: 'Play', sans-serif;

  &__content {
    color: white;
    text-align: center;
    font-weight: bold;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.2;

    > h1 {
      text-transform: uppercase;
      font-size: 3rem;
      margin-bottom: 3.5rem;
    }

    > h2 {
      text-transform: uppercase;
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }

    > h3 {
      font-size: 1rem;
      line-height: 1.5;
    }

    &__faq {
      margin-top: 3rem;
      width: 100%;

      &__question {
        text-align: left;

        &:not(:last-child) {
          border-bottom: 1px solid white;
        }

        &--open {
          > div:first-child > svg {
            transform: rotateZ(180deg);
          }
        }

        > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 1.6rem;
          text-transform: uppercase;

          padding: 2rem 0;
          cursor: pointer;

          > svg {
            width: 26px;
          }
        }

        > div:last-child p {
          margin-bottom: 24px;
          line-height: 1.4;
          white-space: pre-wrap;
        }
      }
    }
  }

  > img {
    display: block;
    margin: 0 auto;
    max-width: 250px;
  }

  @include media(768px, $property: max-width) {
    padding: 120px 40px 0 40px;

    &__content {
      > h1 {
        font-size: 2rem;
        margin-bottom: 2.5rem;
      }

      > h2 {
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
      }

      > h3 {
        font-size: 1rem;
      }

      &__faq {
        margin-top: 2rem;

        &__question {
          > div:first-child {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
</style>
