const install = Vue => {
  Vue.prototype.$navScroll = (hash, topDiff = 0, behavior = 'smooth') => {
    const target = hash
      ? document.querySelector(hash)
      : document.querySelector('body')

    const options = {
      behavior // 'smooth or 'auto'
    }

    if (target) {
      options.top = target?.getBoundingClientRect().top - topDiff

      window.scrollBy(options)
    }
  }
}

export default install
