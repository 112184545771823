import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireContext = require.context('@/assets/images', true, /.+-component\.svg$/)
const suffix = 'Svg'

const components = requireContext.keys().map(item => {
  const name = item.replace(/^.+\/(.+)-component.svg$/, '$1')
  const component = require('@/assets/images' + item.replace(/^\./, '') + '?inline')
  console.log(name, component)

  return [
    upperFirst(camelCase(name)) + suffix,
    component
  ]
})

export default components
