<template>
  <section class="download-section">
    <div class="download-section__container">
      <div class="download-section__body">
        <h3 v-html="$t('download.heading')" />

        <p v-html="$t('download.text')" />

        <p>
          <router-link
            class="button button--compact button--color-2"
            :to="{ name: 'download' }"
            v-html="$t('download.linkDownload')"
          />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DownloadSection'
}
</script>

<style lang="scss" scoped>
.download-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
  background: {
    color: nth($color-custom, 1);
    image:
      url('~@/assets/images/download/computer.png'),
      url('~@/assets/images/download/tray.svg');
    position:
      0 0,
      calc(50% - 130px) 110px;
    repeat: no-repeat;
    size:
      auto 100%,
      auto 380px;
  }

  &__container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: map-get($breakpoint, 'extra-large');
    padding: 0 80px;
  }

  &__body {
    max-width: 600px;
    color: #fff;

    h3 {
      margin-bottom: 20px;
      line-height: 1.2;
      font: {
        weight: 900;
        size: 2rem;
      }
      letter-spacing: -0.03em;
      word-spacing: 0.1em;
    }

    p {
      margin: 0.5em 0;
      font-size: 1.2rem;
      line-height: 130%;
    }

    .button {
      margin-top: 20px;
    }
  }

  // Responsiveness
  @include media(map-get($breakpoint, 'extra-large')) {
    background-position:
      -200px 0,
      calc(50% - 130px) 110px;
  }

  @include media(map-get($breakpoint, 'large')) {
    background-position:
      -400px 0,
      calc(50% - 130px) 110px;
  }

  @include media(map-get($breakpoint, 'medium')) {
    height: 480px;
    background: {
      color: nth($color-custom, 1);
      image: url('~@/assets/images/download/tray.svg');
      position: calc(100% - 20px) $header-height-medium;
      size: auto 380px;
    }

    &__container {
      padding: 0 40px 0 20px;
    }

    &__body {
      padding: 0;

      h3 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 120%;
      }
    }
  }
}
</style>
