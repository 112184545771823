import {
  SET_IS_AUTHENTICATED
} from './mutation-types'

const mutations = {
  [SET_IS_AUTHENTICATED] (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  }
}

export default mutations
