<template>
  <div>
    <div class="modal-wrapper" v-on:click="close">
      <div class="modal" @click.stop>
        <header class="modal__header">
          <slot name="header">
          </slot>
        </header>

        <section class="modal__body">
          <slot name="body">
          </slot>
        </section>

        <footer class="modal__footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    close: Function
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .modal {
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    background: {
      color: #fff;
    };

    &__body {
      max-height: 60vh;
      padding: 0 8px;
      overflow: auto;
    }

    &__footer {
      padding-top: 18px;
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>
