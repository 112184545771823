<template>
  <section class="hero-section">
    <div class="hero-section__container">
      <img src="~@/assets/images/hero/logo-lol.svg" alt="logo-lol" />
      <h1>
        <span v-html="$t('hero.title')" />
      </h1>
      <h3>
        <span v-html="$t('hero.description')"/>
      </h3>

      <a
        class="button hero-section__discover"
        href="https://lolsurprise.playpkxd.com/"
      >
        {{ $t('hero.cta') }}
      </a>
    </div>
    <div class="hero-section__divider" />
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style lang="scss" scoped>
.hero-section {
  position: relative;
  padding-top: $header-height;
  height: 100vh;
  background: {
    color: white;
    image: url('~@/assets/images/hero/character-1.png'), url('~@/assets/images/hero/character-2.png'), url('~@/assets/images/hero/glow-star.png'), url('~@/assets/images/hero/bg.jpeg');
    position: top 500% left -105px, top 180% right 25px, center, bottom;
    repeat: no-repeat;
    size: auto 94%, auto 90%, auto 125%, cover;
  }

  &__container {
    flex: 1;
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    max-width: map-get($breakpoint, 'extra-large');
    margin: 8px auto 8px auto;

    > img {
      height: 168px;
      margin-bottom: 32px;
    }

    > h1 {
      font-size: 44px;
      font-family: Play, Lato, sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      line-height: 64px;
      margin-bottom: 34px;

      > span {
        box-shadow: -14px 0 0 14px #ed69b7, 14px 0 0 14px #ed69b7;
        background-color: #ed69b7;
      }
    }

    > h3 {
      font-size: 24px;
      font-family: Play, Lato, sans-serif;
      font-weight: 700;
      text-align: center;
      line-height: 42px;

      > span {
        box-shadow: -11px 0 0 11px #ed69b7, 11px 0 0 11px #ed69b7;
        background-color: #ed69b7;
      }
    }
  }

  &__discover {
    z-index: 2;
    margin: 32px 0 20px 0;
    max-width: 300px;
  }

  &__divider {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;

    background-image: url('~@/assets/images/hero/divider.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: bottom left -22px;
    z-index: 1;
  }

  @include media(map-get($breakpoint, 'extra-large')) {
    background: {
      image: url('~@/assets/images/hero/character-1.png'), url('~@/assets/images/hero/character-2.png'), url('~@/assets/images/hero/glow-star.png'), url('~@/assets/images/hero/bg.jpeg');
      position: top 180% left -95px, top 120% right -4px, center, bottom;
      repeat: no-repeat;
      size: auto 80%, auto 75%, auto 125%, cover;
    }

    &__container {
      > img {
        height: 150px;
        margin-bottom: 32px;
      }

      > h1 {
        font-size: 40px;
        line-height: 45px;

        > span {
          box-shadow: -8px 0 0 8px #ed69b7, 8px 0 0 8px #ed69b7;
        }
      }

      > h3 {
        font-size: 24px;
        line-height: 42px;

        > span {
          box-shadow: -8px 0 0 8px #ed69b7, 8px 0 0 8px #ed69b7;
          background-color: #ed69b7;
        }
      }
    }
  }

  @include media(map-get($breakpoint, 'large')) {
    background: {
      image: url('~@/assets/images/hero/character-2.png'), url('~@/assets/images/hero/glow-star.png'), url('~@/assets/images/hero/bg.jpeg');
      position:  top 130% right -4px, top -14px left -200px, bottom;
      repeat: no-repeat;
      size: auto 84%, auto 90%, cover;
    }

    &__container {
      align-items: flex-start;
      margin: 8px 72px;
      height: 700px;

      > img {
        height: 130px;
        margin-bottom: -4px;
        margin-left: -38px;
        z-index: 1;
      }

      > h1 {
        font-size: 32px;
        line-height: 45px;
        text-align: left;
        margin-bottom: 22px;

        > span {
          box-shadow: -8px 0 0 8px #ed69b7, 8px 0 0 8px #ed69b7;
        }
      }

      > h3 {
        font-size: 20px;
        line-height: 32px;
        text-align: left;

        > span {
          box-shadow: -8px 0 0 8px #ed69b7, 8px 0 0 8px #ed69b7;
          background-color: #ed69b7;
        }
      }
    }

    &__discover {
      z-index: 2;
      margin: 32px 0 20px -16px;
    }
  }

  @include media(map-get($breakpoint, 'medium')) {
    padding-top: $header-height-compact;
    padding-bottom: 72px;
    height: auto;

    background: {
      image: url('~@/assets/images/hero/character-2.png'), url('~@/assets/images/hero/glow-star.png'), url('~@/assets/images/hero/bg.jpeg');
      position:  bottom -150px left 55%, top -150px center, bottom;
      repeat: no-repeat;
      size: auto 52%, auto 90%, cover;
    }

    &__container {
      align-items: center;
      justify-content: start;
      margin: 8px 16px;

      > img {
        height: 120px;
        margin: 18px 0;
      }

      > h1 {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        margin-bottom: 22px;
      }

      > h3 {
        font-size: 16px;
        line-height: 32px;
        text-align: center;
      }
    }

    &__discover {
      z-index: 2;
      margin-top: 32px;
    }

    &__divider {
      bottom: -35px;
      height: 75px;
    }
  }
}
</style>
