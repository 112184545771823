<template>
  <img :src="`https://creative-content.aftvrsys.com/image-alternatives/${light ? 'light/' : ''}favicon-512x512.png`" class="hero-section__logo" />
</template>

<script>
export default {
  name: 'LogoPKXD',

  props: {
    light: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
